import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { simpleContactPageTplData } from "../data/page-tpl-simple-contact.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import PageHead from "../components/Page/PageHead";
import GetInTouch from "../components/GetInTouch";

const pageRequestADemo = ({ pageContext: { id } }) => {
    const seoPageData = getCurrentPageSeo(id);
    const pageData = simpleContactPageTplData(id);

    return (
        <Layout pageTitle={seoPageData.title}
            navbarVariant="white"
            backgroundVariant="red"
            hideNavbarButton
            hideBanner
            fullWidth
        >
            {/* SEO Metas */}
            <Seo post={seoPageData} />

            {/* Page head */}
            <Container>
                <PageHead
                    specialStylingForRequestDemoPage={true}
                    variant={"white"}
                    title={pageData.pageHeader?.header}
                    text={pageData.pageHeader?.text}
                />
            </Container>

            {/* GetInTouch Section */}
            <Section className="-mobile-full">
                <GetInTouch hideHeader={true} />
            </Section>
        </Layout>
    );
};

export default pageRequestADemo;
