// Default imports
import React, { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Row, Col, Container } from "react-bootstrap";

import SectionHeader from "../components/SectionHeader";
import Wave from "../components/Wave";
import FormGroup from "../components/Form/FormGroup";

// Import SVG
import AtLogoIcon from "../icons/wyden-logo-icon.svg";

import { getintouchCompData } from "../data/components-getintouch.data";
import useFormApiSender from "../hooks/useFormApiSender";
import { trackEvent } from "../plausible";

const GetInTouch = ({ hideHeader, pageTitle }) => {
    const compData = getintouchCompData();

    const [cookies, setCookie, removeCookie] = useCookies(["hubspotutk"]);

    const {
        formApiEndpoint,
        label,
        header,
        textBottom,
        selectPlaceholder,
        optionalText,
        buttonText,
        buttonTextSending = "Sending...",
        textSuccess = "Thank you!",
        formId,
        countries,
        businesses,
        inputLabels,
    } = compData.wp.mdaThemeOptions.themeSettings.getintouch;

    // Form fields
    const [formFields, setFormFields] = useState([
        {
            size: "medium",
            tag: "select",
            placeholder: selectPlaceholder,
            name: "segment",
            options: businesses,
            required: true,
        },
        {
            size: "medium",
            tag: "input",
            name: "companyName",
            type: "text",
            maxLength: 255,
            required: true,
        },
        {
            size: "medium",
            tag: "input",
            name: "firstName",
            type: "text",
            maxLength: 64,
            required: true,
        },
        {
            size: "medium",
            tag: "input",
            name: "lastName",
            type: "text",
            maxLength: 64,
            required: true,
        },
        {
            tag: "input",
            name: "email",
            type: "email",
            maxLength: 64,
            required: true,
        },
        {
            tag: "select",
            placeholder: selectPlaceholder,
            name: "country",
            options: countries.map(({ label }) => {
                return { label: label, value: label };
            }),
            required: true,
        },
        {
            tag: "textarea",
            name: "notes",
            maxLength: 500,
            rows: 4,
        },
        {
            tag: "input",
            name: "code",
            type: "hidden",
        },
        {
            tag: "input",
            name: "hutk",
            type: "hidden",
            value: cookies.hubspotutk,
        },
        {
            tag: "input",
            name: "uri",
            type: "hidden",
            value:
                typeof window !== `undefined`
                    ? window.location.href?.replace(/https?:\/\/(www\.)?/, "")
                    : "",
        },
    ]);

    // States
    const [success, setSuccess] = useState(false);
    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState([]);

    // Refs
    const getintouchForm = useRef();
    // const getintouchForm = useRef();
    // const getintouchForm = useRef();

    // Handlers
    const handleChange = (e) => {
        // Check if field is select or not
        const changedName =
            e.type === "select" ? e.name : e.target.getAttribute("name");
        const changedValue = e.type === "select" ? e.value : e.target.value;

        // Set the value dependencies
        const dependenciesFormField = formFields.map((field) => {
            if (field.name === changedName) {
                field.value = changedValue;
            }

            // Dependency [1]
            if (field.name === "companyName" && changedName === "segment") {
                field.required = changedValue !== "retailTrader";
            }

            // Dependency [2]
            if (field.name === "notes" && changedName === "segment") {
                field.required =
                    changedValue === "retailTrader" || changedValue === "other";
            }

            // Dependency [3]
            if (field.name === "code" && changedName === "country") {
                field.value = countries.find(
                    ({ label }) => label === changedValue
                )?.value;
            }

            return field;
        });
        setFormFields(dependenciesFormField);

        // Reset errors in field
        const currentErrors = errors.filter(
            (error) => error.fieldName !== changedName
        );
        if (JSON.stringify(currentErrors) !== JSON.stringify(errors)) {
            setErrors(currentErrors);
        }
    };

    const handleSuccess = () => {
        setSending(false);
        setSuccess(true);
        trackEvent("Contact Form: Submitted", {
            props: {
                "contact-form-page-title": pageTitle,
            }
        });
    };

    const handleError = (err = []) => {
        setSending(false);
        setErrors(err);
        trackEvent("Contact Form: API error", {
            props: {
                "contact-form-page-title": pageTitle,
            }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Additional required validation
        const validateErrors = requiredValidate();
        setErrors([...validateErrors, ...errors]);

        if (errors.length === 0 && validateErrors.length === 0) {
            setSending(true);

            useFormApiSender({
                url: formApiEndpoint,
                form: getintouchForm.current,
                formId: formId,
                onSuccess: handleSuccess,
                onError: handleError,
            });
        } 
    };

    // Validate if all required fields has value
    const requiredValidate = () => {
        // Field errors
        const validateErrors = [];

        // Empty fields
        const emptyReqFields = formFields.filter(
            (field) =>
                field.required === true && (!field.value || field.value === "")
        );

        // Add errors for invalid fields
        emptyReqFields.forEach((field) => {
            const isErrorExists =
                errors.findIndex(({ fieldName, message }) => {
                    return (
                        fieldName === field.name && (!message || message === "")
                    );
                }) > -1;

            if (!isErrorExists) {
                validateErrors.push({ fieldName: field.name, message: "" });
            }
        });

        return validateErrors;
    };

    useEffect(() => {
        if (errors.length > 0) {
            const errorFieldName = errors[0].fieldName;
            const errorField = getintouchForm.current.querySelector([
                `label[for="${errorFieldName}"]`, // not input because of react-select fields
            ]);

            if (errorField) {
                errorField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }
    }, [errors]);

    return (
        <Container>
            <Row className="get-in-touch" id="get-in-touch">
                <Col xs="24">
                    <Row className="get-in-touch__content">
                        {!hideHeader && (
                            <Col
                                xl={{ span: 14, offset: 5 }}
                                xs={{ span: 22, offset: 1 }}
                            >
                                <div className="get-in-touch__headline">
                                    <SectionHeader
                                        labelBottom={label}
                                        header={header}
                                        variant="light"
                                    />
                                </div>
                            </Col>
                        )}

                        <Col
                            xl={{ span: 14, offset: hideHeader ? 1 : 5 }}
                            xs={{ span: 22, offset: 1 }}
                        >
                            <form
                                ref={getintouchForm}
                                className="get-in-touch__form"
                                onSubmit={handleSubmit}
                            >
                                {/* Programmicly create fields from fields array */}
                                {formFields.map((formField) => (
                                    <FormGroup
                                        // Classess
                                        className={`get-in-touch__form-group -medium ${
                                            formField.required
                                                ? "-is-required"
                                                : ""
                                        }`}
                                        fieldClassName={`get-in-touch__${formField.tag}  -transparent-dark-color-scheme`}
                                        labelClassName="get-in-touch__input-label"
                                        // Data
                                        label={
                                            inputLabels.find(
                                                (inputLabel) =>
                                                    inputLabel.fieldName ===
                                                    formField.name
                                            )?.label
                                        }
                                        key={formField.name}
                                        optionalText={optionalText}
                                        placeholder={
                                            inputLabels.find(
                                                (inputLabel) =>
                                                    inputLabel.fieldName ===
                                                    formField.name
                                            )?.placeholder
                                        }
                                        disabled={success}
                                        errors={errors}
                                        onChange={handleChange}
                                        {...formField}
                                    />
                                ))}

                                <div className="get-in-touch__form-group -medium">
                                    <button
                                        type="submit"
                                        className="get-in-touch__button -red-inverted"
                                        disabled={
                                            success ||
                                            errors.filter(
                                                ({ fieldName }) =>
                                                    fieldName !== -1
                                            ).length > 0
                                        }
                                    >
                                        {sending ? (
                                            <>
                                                <AtLogoIcon className="get-in-touch__form-spinner" />{" "}
                                                {buttonTextSending}
                                            </>
                                        ) : (
                                            buttonText
                                        )}
                                    </button>
                                    {success ? (
                                        <p className="get-in-touch__success-text">
                                            {textSuccess}
                                        </p>
                                    ) : (
                                        <p className="get-in-touch__information">
                                            {textBottom}
                                        </p>
                                    )}

                                    {errors
                                        .filter(
                                            (error) => error.fieldName === -1
                                        )
                                        .map((error, index) => (
                                            <p
                                                key={index}
                                                className="get-in-touch__error"
                                                dangerouslySetInnerHTML={{
                                                    __html: error.message,
                                                }}
                                            />
                                        ))}
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default GetInTouch;
