// Default imports
import React from "react";

import ButtonText from "./ButtonText";
import KenjoCareer from "../components/KenjoCareer";

const OpenPositions = ({ header, emptyText, positions }) => {
  return (
    <div className="open-positions" id="open_positions">
      <h2 className="open-positions__header">{header}</h2>
      <KenjoCareer />
    </div>
  );
};

const OpenPositionsItem = ({ header, location, text, links }) => (
  <div className="open-positions__item">
    <div className="open-positions__label">{location}</div>
    <h3 className="open-positions__title">{header}</h3>
    <p className="open-positions__description">{text}</p>

    <div className="open-positions__links">
      {links &&
        links.map(({ type, link, text, file }, index) => {
          let btnLink = "";
          if (type === "file") {
            btnLink = {
              url: file.localFile.publicURL,
              title: text,
              target: "_blank",
            };
          } else {
            btnLink = link;
          }

          return (
            <ButtonText
              key={`${btnLink.title}-${index}`}
              className="open-positions__link"
              variant="red"
              {...btnLink}
            >
              {btnLink?.title}
            </ButtonText>
          );
        })}
    </div>
  </div>
);

export default OpenPositions;
