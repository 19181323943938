import Plausible from 'plausible-tracker'

const {enableAutoOutboundTracking,enableAutoPageviews,trackEvent,trackPageview} = Plausible({
  domain: process.env.HOST_NAME,
  hashMode: true,
})

export {
    enableAutoOutboundTracking,
    enableAutoPageviews,
    trackEvent,
    trackPageview
}