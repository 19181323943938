import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import Layout from "../components/Layout";
import Section from "../components/Section";

import { newsPageTplData } from "../data/page-tpl-news.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import PageHead from "../components/Page/PageHead";
import Articles from "../components/Articles";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";

const pageResources = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = newsPageTplData(id);

  return (
    <Layout pageTitle={seoPageData.title}>
      <Seo post={seoPageData} />
      {/* Page head */}
      <Container>
        <PageHead {...pageData.pageHeader} />
      </Container>

      {/* Articles Section */}
      <Section className="-small-margin-bottom">
        <Container>
          <Articles {...pageData.postsList}  temp='news'/>
        </Container>
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch pageTitle={seoPageData.title} />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default pageResources;
