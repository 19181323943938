import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { resourcesPageTplData } from "../data/page-tpl-resources.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import ButtonText from "../components/ButtonText";
import PageHead from "../components/Page/PageHead";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import AccordionLinksSection from "../components/AccordionLinksSection";

const pageResources = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = resourcesPageTplData(id);

  return (
    <Layout pageTitle={seoPageData.title}>
      {/* SEO Metas */}
      <Seo post={seoPageData} />

      {/* Page head */}
      <Container>
        <PageHead
          title={pageData.pageHeader.header}
          text={pageData.pageHeader.text}
        />
      </Container>

      {/* Resources Section */}
      <Section className="resources -small-margin-bottom -mobile-full">
        <Container>
          {pageData.button?.link && (
            <Section className="mb-5">
              <ButtonText variant="red" {...pageData.button.link}>
                {pageData.button.link.title}
              </ButtonText>
            </Section>
          )}
          <Section>
            <AccordionLinksSection
              {...pageData.generalInformation}
              id="general-information"
              containerClassName="px-0 -border-bottom"
            />
            <AccordionLinksSection
              {...pageData.guides}
              id="quick-guides"
              containerClassName="px-0 -border-bottom"
            />
            <AccordionLinksSection
              {...pageData.docs}
              id="documentation"
              className="px-0"
            />
          </Section>
        </Container>
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch pageTitle={seoPageData.title} />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default pageResources;
