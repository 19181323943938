import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { visionPageTplData } from "../data/page-tpl-vision.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import PageHead from "../components/Page/PageHead";
import LifeAtAlgotrader from "../components/LifeAtAlgotrader";
import NewsAndInsights from "../components/NewsAndInsights";
import OurHistory from "../components/OurHistory";
import GetInTouch from "../components/GetInTouch";
import ImageSection from "../components/ImageSection";

const pageVision = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = visionPageTplData(id);

  return (
    <Layout pageTitle={seoPageData.title}>
      {/* SEO Metas */}
      <Seo post={seoPageData} />

      {/* Page head */}
      <Container>
        <PageHead
          title={pageData.pageHeader.header}
          text={pageData.pageHeader.text}
        />
      </Container>

      {/* Section Intitutional Trading */}
      <ImageSection
        containerClassName="pt-2"
        contentClassName="pt-3"
        imageSection={pageData.institutionalTrading.imageSection}
      />

      {/* Section Life At Algotrader  */}
      <LifeAtAlgotrader {...pageData.lifeAtAlgotrader} />

      {/* Section Our History */}
      <OurHistory containerClassName="pt-lg-0" {...pageData.ourHistory} />

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch pageTitle={seoPageData.title} />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default pageVision;
