import * as React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { getCurrentPageSeo } from "../helpers/richContentHelpers";
import { infinityProductComparisonPageData } from "../data/page-tpl-infinity-product-comparison.data";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Hero from "../componentsSections/Hero";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import ImageSection from "../components/ImageSection";
import TableSection from "../components/TableSection";
import GetInTouchButton from "../components/GetInTouchButton";

const Page = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = infinityProductComparisonPageData(id);

  return (
    <>
      <Layout pageTitle={seoPageData.title} navbarVariant={pageData.hero.image ? "transparent" : undefined}>
        {/* SEO Metas */}
        <Seo post={seoPageData} />

        {/* Page head */}
        <Hero {...pageData.hero} textSize="big" />

        {/* Table Section */}
        <Section>
          <TableSection {...pageData.tableSection} />
        </Section>

        <Section>
          <GetInTouchButton />
        </Section>

        {/* Single Image Section */}
        <Section>
          <ImageSection imageSection={pageData.imageSection} />
        </Section>

        {/* GetInTouch Section */}
        <Section className="-mobile-full">
          <GetInTouch pageTitle={seoPageData.title} />
        </Section>

        {/* News and insights Section */}
        <Section>
          <NewsAndInsights />
        </Section>
      </Layout>
    </>
  );
};

export default Page;
