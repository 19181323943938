import React from "react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import TextContent from "../components/TextContent";

// import { textPageTplData } from "../data/page-tpl-text.data";

const PageText = ({ data, pageContext: { id } }) => {
  // const pageData = textPageTplData(id);
  const seoPageData = getCurrentPageSeo(id);

  return (
    <Layout pageTitle={seoPageData.title}>
      {/* SEO Data */}
      <Seo post={seoPageData} />

      {/* Content Section */}
      <Section>
        <TextContent {...data.wpPage} />
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch pageTitle={seoPageData.title} />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query PageText($id: String) {
    wpPage(id: { eq: $id }) {
      title
      content
      template {
        ... on WpTemplate_Text {
          textRichContent {
            pageHead {
              text
              title
            }
            flexibleContent {
              ... on WpTemplate_Text_Textrichcontent_FlexibleContent_Text {
                fieldGroupName
                text
                margin {
                  screenSize
                  side
                  value
                }
              }
              ... on WpTemplate_Text_Textrichcontent_FlexibleContent_Button {
                fieldGroupName
                text
                link {
                  target
                  title
                  url
                }
                margin {
                  screenSize
                  side
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PageText;
