import React, { useEffect, useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { CookiesProvider } from "react-cookie";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";

// Import base styles
import "../scss/base.scss";

// Import local components
import Navbar from "./Navbar";
import Newsletter from "./Newsletter";
import Footer from "./Footer";

// Animations
import motion from "framer-motion";
import TransitionLink, { TransitionState } from "gatsby-plugin-transition-link";
import Cookiesbar from "./Cookiesbar";

import BodyPortal from "../portals/BodyPortal";
import DangerousInnerHtmlLoader from "./DangerousInnerHtmlLoader";
import { LINKEDIN_TRACKING_TAG } from "../trackingTags/trackingTags";
import NoticeBanner from "./NoticeBanner";
import { enableAutoPageviews, enableAutoOutboundTracking } from "../plausible";


// Layout
const Layout = ({
    children,
    navbarVariant,
    backgroundVariant,
    fullWidth,
    hideNavbarButton,
    hideBanner,
    pageTitle
}) => {
    const [isBannerOpen, setIsBannerOpen] = useState(true);

    const plausibleInit = useRef(false);

    useEffect(() => {
      if(!plausibleInit.current && typeof window !== 'undefined') {
        plausibleInit.current = true;
        enableAutoPageviews();
        enableAutoOutboundTracking();
      }
    }, []);

    const {
        wp: { seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            altText
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `);

console.log("Layout.js: Layout: ", seo);
    return (
        <SEOContext.Provider value={{ global: seo }}>
            <CookiesProvider>
                {!hideBanner && (
                    <NoticeBanner
                        isBannerOpen={isBannerOpen}
                        setIsBannerOpen={setIsBannerOpen}
                    />
                )}

                <header>
                    <Navbar
                        isBannerOpen={isBannerOpen && !hideBanner}
                        variant={navbarVariant}
                        hideButton={hideNavbarButton}
                    />
                </header>
                <main
                    className={`page-wrapper ${
                        backgroundVariant === "red" ? "-red" : ""
                    } ${
                        isBannerOpen && !hideBanner
                            ? "page-wrapper__notice-banner"
                            : ""
                    }`}
                >
                    {children}
                    <Newsletter fullWidth={fullWidth} pageTitle={pageTitle}/>
                </main>
                <aside>
                    <Cookiesbar />
                </aside>
                <Footer fullWidth={fullWidth} />
                <BodyPortal>
                    <DangerousInnerHtmlLoader
                        id="linkedin-insight-tag"
                        htmlContent={LINKEDIN_TRACKING_TAG}
                    />
                </BodyPortal>
            </CookiesProvider>
        </SEOContext.Provider>
    );
};

export default Layout;
