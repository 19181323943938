import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { teamPageTplData } from "../data/page-tpl-team.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import PageHead from "../components/Page/PageHead";
import Team from "../components/Team";
import TeamHeader from "../components/TeamHeader";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";

const PageTeam = ({ pageContext: { id } }) => {
  const pageData = teamPageTplData(id);
  const seoPageData = getCurrentPageSeo(id);

  return (
    <Layout pageTitle={seoPageData.title}>
      <Seo post={seoPageData} />
      {/* Page head */}
      <Container>
        <PageHead {...pageData.pageHeader} />
      </Container>

      <Section className="-small-margin-bottom">
        <Container>
          <TeamHeader {...pageData.additionalInfo}  />
        </Container>
      </Section>

      {/* Articles Section */}
      <Section className="-small-margin-bottom">
        <Container>
          <Team />
        </Container>
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch pageTitle={seoPageData.title} />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default PageTeam;
